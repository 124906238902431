var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ul",
        _vm._l(_vm.requiredFiles[_vm.mode], function (f) {
          return _c("li", { key: f.value }, [
            _vm._v(
              _vm._s(f.value) +
                "." +
                _vm._s(f.extension) +
                " ... " +
                _vm._s(f.text)
            ),
          ])
        }),
        0
      ),
      _c("v-file-input", {
        attrs: {
          chips: "",
          multiple: "",
          accept: ".csv, .CSV",
          label: "ファイルを選択してください",
          rules: _vm.rules,
        },
        on: { change: _vm.refreshFileList },
        model: {
          value: _vm.selectedFiles,
          callback: function ($$v) {
            _vm.selectedFiles = $$v
          },
          expression: "selectedFiles",
        },
      }),
      _c(
        "v-btn",
        {
          staticClass: "mr-3",
          attrs: {
            color: "primary",
            disabled:
              (_vm.yw &&
                _vm.selectedFiles.length <
                  _vm.requiredFiles[_vm.mode].length) ||
              _vm.queueHasDuplicates,
            loading: _vm.loading,
          },
          on: { click: _vm.checkFiles },
        },
        [_vm._v(" アップロード ")]
      ),
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.uploadProgress > 0,
            expression: "uploadProgress>0",
          },
        ],
        attrs: {
          color: "primary",
          size: "30",
          rotate: "270",
          value: _vm.uploadProgress,
        },
      }),
      _c("pre-upload-dialog", {
        ref: "preUploadDialog",
        on: { "file-match": _vm.uploadFiles },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }