var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm.mode == "file-matching-required"
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { attrs: { color: "warning" } }, [
                    _vm._v("mdi-warning"),
                  ]),
                  _vm._v("不明なファイルが見つかりました "),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm._v(" ファイルの対応づけを行ってください："),
                  _c("br"),
                  _vm._l(_vm.details.requiredFiles, function (file, i) {
                    return _c(
                      "v-row",
                      { key: "upload-file-" + file.text },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "9" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.details.queue,
                                "item-text": "name",
                                label: file.text,
                                error: _vm.uploadListHasDuplicates,
                              },
                              model: {
                                value: _vm.uploadListValues[i],
                                callback: function ($$v) {
                                  _vm.$set(_vm.uploadListValues, i, $$v)
                                },
                                expression: "uploadListValues[i]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled:
                          _vm.uploadList.includes(undefined) ||
                          _vm.uploadListHasDuplicates,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmFileMatch()
                        },
                      },
                    },
                    [_vm._v(" アップロード ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "grey darken-2" },
                      on: {
                        click: function ($event) {
                          return _vm.hide()
                        },
                      },
                    },
                    [_vm._v("キャンセル")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }